<form id="informationForm" novalidate #informationForm="ngForm" class="submission-form">
    <section class="mat-input-section">
        <div class="heading-2 mt-4">
            STEP 1: PERSONAL AND COMPANY INFORMATION
        </div>
        <div>
            <form [formGroup]="validatedForm">
                <div class="row mt-2">
                    <div class="col-10">
                        <label class="paragraph"
                            [ngClass]="{'mat-invalid-input': validatedForm.get('urbanPlanner.graduationYear').invalid, 'mat-valid-input': validatedForm.get('urbanPlanner.graduationYear').valid}">
                            Which year did you graduate as a planner?
                        </label>
                    </div>
                    <div class="col-10" formGroupName="urbanPlanner">
                        <input name="plannerGraduationYear" class="paragraph mat-input-section" type="number"
                            pattern="\d{4}" formControlName="graduationYear" required>
                        <div *ngIf="validatedForm.get('urbanPlanner.graduationYear').errors?.yearInvalid && (validatedForm.get('urbanPlanner.graduationYear').dirty || validatedForm.get('urbanPlanner.graduationYear').touched)"
                            class="error text-size">
                            The year cannot be greater than the current year.
                        </div>
                        <div *ngIf="validatedForm.get('urbanPlanner.graduationYear').invalid && (validatedForm.get('urbanPlanner.graduationYear').dirty || validatedForm.get('urbanPlanner.graduationYear').touched)"
                            class="error text-size">
                            Please enter a valid year.
                        </div>
                    </div>
                </div>
            </form>
            <div class="row mt-2">
                <div class="col-10"><label class="paragraph"
                        [ngClass]="{'mat-invalid-input': graduationInstitution.invalid, 'mat-valid-input': graduationInstitution.valid}">
                        From which institution did you graduate?
                    </label>
                </div>
                <div class="col-10">
                    <input name="graduationInstitution" class="paragraph mat-input-section" type="text"
                        [(ngModel)]="userForm.urbanPlanner.graduationInstitution" required
                        #graduationInstitution="ngModel">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-10">
                    <label class="paragraph text-size"
                        [ngClass]="{'mat-invalid-input': statutoryPlanningExperienceYears.invalid, 'mat-valid-input': statutoryPlanningExperienceYears.valid}">
                        How many years of experience do you have in the field of land use management (statutory
                        planning) specifically?
                    </label>
                </div>
                <div class="col-10">
                    <input name="statutoryPlanningExperienceYears" class="paragraph mat-input-section" type="number"
                        [(ngModel)]="userForm.urbanPlanner.yearsOfExperience" required
                        #statutoryPlanningExperienceYears="ngModel">
                </div>
            </div>
            <div class="row mt-2 spacing-between-sections">
                <div class="col-10">
                    <label class="paragraph"
                        [ngClass]="{'mat-invalid-input': companyNameOperatedUnder.invalid, 'mat-valid-input': companyNameOperatedUnder.valid}">
                        Under which name does you/your company operate?
                    </label>
                </div>
                <div class="col-10">
                    <input name="companyNameOperatedUnder" class="paragraph mat-input-section" type="text"
                        [(ngModel)]="userForm.urbanPlanner.companyDetail.name" required
                        #companyNameOperatedUnder="ngModel">
                </div>
            </div>
            <h5 class="heading-2">Property Identification</h5>
            <p class="paragraph bold">
                <span class="required-text">|</span>
                Please search and select your company/office on the map. You only have three attempts.
            </p>
            <div class="row text-center map-container">
                <div class="map-plugin-container col-12 col-md-8">
                    <app-map-search (selectedProperty)="handleSelectedPropertyChange($event)"
                        (selectedUnitNumber)="handleSelectedUnitChange($event)"
                        (scriptLoadStatus)="handleScriptLoadStatus($event)">
                    </app-map-search>
                </div>
                <div class="map-sidenav-container col-12 col-md-4">
                    <div class="sidenav-header">
                        <h2>Confirm Property</h2>
                    </div>
                    <div class="sidenav-body">
                        <div *ngIf="cadasterPicture != 1">
                            <div class="sidenav-sub-heading">
                                <h2>Property Boundary</h2>
                            </div>
                            <div id="sidebar-map" class="small-map">
                                <img [src]="cadasterPicture" class="cadastre-image" alt="Cadaster Picture">
                            </div>
                            <div class="button-container">
                                <button class="incorrect-button" [class.selected]="selectedBoundaryButton === 1"
                                    (click)="selectBoundaryButton(1)" confirmPropertyBoundary="1">
                                    Incorrect
                                </button>
                                <button class="unsure-button" [class.selected]="selectedBoundaryButton === 2"
                                    (click)="selectBoundaryButton(2)" confirmPropertyBoundary="2">
                                    Unsure
                                </button>
                                <button class="correct-button" [class.selected]="selectedBoundaryButton === 3"
                                    (click)="selectBoundaryButton(3)" confirmPropertyBoundary="3">
                                    Correct
                                </button>
                            </div>
                        </div>
                        <div class="sidenav-sub-heading">
                            <h2>Property Details</h2>
                        </div>
                        <div class="property-details-table">
                            <table>
                                <tr>
                                    <td>Unit Number</td>
                                    <td>{{ this.unitNumber }}</td>
                                </tr>
                                <tr>
                                    <td>Erf/Portion Nr</td>
                                    <td>{{ this.erfOrPortionNumber }}</td>
                                </tr>
                                <tr>
                                    <td>Short Address</td>
                                    <td>{{ this.shortAddress }}</td>
                                </tr>
                                <tr>
                                    <td>Suburb Name</td>
                                    <td>{{ this.suburbName }}</td>
                                </tr>
                                <tr>
                                    <td>City/Town</td>
                                    <td>{{ this.cityTown }}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="button-container">
                            <button class="incorrect-button" [class.selected]="selectedButton === 1"
                                (click)="selectButton(1)" confirmPropertyDetail="1">
                                Incorrect
                            </button>
                            <button class="unsure-button" [class.selected]="selectedButton === 2"
                                (click)="selectButton(2)" confirmPropertyDetail="2">
                                Unsure
                            </button>
                            <button class="correct-button" [class.selected]="selectedButton === 3"
                                (click)="selectButton(3)" confirmPropertyDetail="3">
                                Correct
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="this.confirmPropertyBoundary === 1 || this.confirmPropertyBoundary === 2">
                <div class="heading-3">
                    Property Boundary
                </div>
                <div class="heading-5">
                    <span class="burnt-red">| </span>
                    <span>Please explain your concern regarding the boundary indicated:</span>
                </div>
                <div class="row">
                    <div class="col-8">
                        <textarea style="height: 100px; resize: none;" [matTextareaAutosize]="true"
                            [(ngModel)]="propertyDetailForm.propertyBoundaryConcern" id="txtPropertyBoundaryConcern"
                            name="propertyBoundaryConcern" class="paragraph">
                        </textarea>
                    </div>
                </div>
            </div>
            <div *ngIf="confirmPropertyDetail == 1 || confirmPropertyDetail == 2">
                <div class="heading-3">
                    Property Details
                </div>
                <div class="heading-5">
                    Please provide the correct details:
                </div>
                <div class="row propertyDetailsBoundaryInput">
                    <div class="col-4">
                        <label id="lblPropertyAddress" for="txtPropertyAddress" class="paragraph">
                            Property address
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtPropertyAddress" name="propertyAddress" class="paragraph not-required"
                            [(ngModel)]="propertyDetailForm.propertyAddress">
                    </div>
                </div>
                <div class="row propertyDetailsBoundaryInput">
                    <div class="col-4">
                        <label id="lblPropertyDescription" for="txtPropertyDescription" class="paragraph">
                            Property description:
                        </label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="txtPropertyDescription" name="propertyDescription"
                            class="paragraph not-required" [(ngModel)]="propertyDetailForm.propertyDescription">
                    </div>
                </div>
            </div>
            <section>
                <div class="row top-spacing">
                    <div class="col-10">
                        <label class="paragraph mat-not-required">
                            If applicable - your company registration number?
                        </label>
                    </div>
                    <div class="col-10">
                        <input name="companyRegistrationNumber" class="paragraph mat-input-section" type="text"
                            [(ngModel)]="userForm.urbanPlanner.companyDetail.companyRegistrationNumber"
                            companyRegistrationNumber="ngModel">
                    </div>
                </div>
                <div class="row">
                    <div class="col-10">
                        <label class="paragraph mat-not-required">
                            If applicable - your company VAT number?
                        </label>
                    </div>
                    <div class="col-10">
                        <input name="companyVatNumber" class="paragraph mat-input-section" type="text"
                            [(ngModel)]="userForm.urbanPlanner.companyDetail.companyVatNumber"
                            companyVatNumber="ngModel">
                    </div>
                </div>
            </section>
            <section class="submission-form">
                <div class="paragraph bold top-spacing">
                    <span class="burnt-red">|</span>
                    At which municipal council(s) do you mostly submit planning applications?
                </div>
                <div class="row">
                    <div class="col-12">
                        <div *ngFor="let option of municipalCouncilLookup  let index = i" class="paragraph mt-1">
                            <label class="checkbox-container paragraph m-1">
                                <span class="paragraph">
                                    {{ option.value }}
                                    <span
                                        [ngClass]="{'specify-text': userForm.municipalCouncilsMostlySubmittedToOther?.length == 0}"
                                        *ngIf="municipalOtherCheck(option)">
                                        - Please specify
                                    </span>
                                </span>
                                <input type="checkbox" [(ngModel)]="option.isChecked"
                                    [name]="municipalCouncilsMostlySubmittedToOptions"
                                    #municipalCouncilsMostlySubmittedToOptions="ngModel"
                                    (change)="selectedMunicipalCouncil(option)">
                                <span class="checkmark"></span>
                            </label>
                            <div class="mt-2" *ngIf="option.isChecked && municipalOtherCheck(option)">
                                <input name="municipalCouncilsMostlySubmittedToOther"
                                    class="paragraph mat-input-section" type="text"
                                    [(ngModel)]="userForm.urbanPlanner.companyDetail.municipalCouncilOther" required
                                    #municipalCouncilsMostlySubmittedToOther="ngModel">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="paragraph bold">
                    <span class="burnt-red">|</span>
                    Indicate any type of land development applications in which you have extensive experience and
                    knowledge:
                </div>
                <div class="row">
                    <div class="col-12">
                        <div *ngFor="let option of this.landDevelopmentType; let index = i">
                            <label class="checkbox-container paragraph m-1">
                                <span class="paragraph">
                                    {{ option.value }}
                                    <span [ngClass]="{'specify-text': userForm.landDevelopmentTypeOther?.length == 0}"
                                        *ngIf="planningOtherCheck(option)">
                                        - Please specify
                                    </span>
                                </span>
                                <input type="checkbox" [(ngModel)]="option.isChecked"
                                    [name]="planningApplicationsExperienceAndKnowledgeOptions"
                                    #planningApplicationsExperienceAndKnowledgeOptions="ngModel"
                                    (change)="selectedLandDevelopmentType(option)">
                                <span class="checkmark"></span>
                            </label>
                            <div class="mt-2" *ngIf="planningOtherCheck(option)">
                                <div class="row mt-2">
                                    <div class="col-10">
                                        <input name="planningApplicationsExperienceAndKnowledgeOther" type="text"
                                            class="paragraph mat-input-section mat-input-section-other"
                                            [(ngModel)]="userForm.urbanPlanner.companyDetail.landDevelopmentTypeOther"
                                            required #planningApplicationsExperienceAndKnowledgeOther="ngModel">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="paragraph bold">
                    <span class="burnt-red">|</span>
                    Do you have proven experience in conducting feasibility/viability studies, calculating the maximum
                    projected ROI for developments projects?
                </div>
                <div class="row">
                    <mat-radio-group class="col-10" name="experienceInMarketResearch"
                        [(ngModel)]="experienceInMarketResearch" (change)="setExperienceInMarketResearch($event.value)">
                        <mat-radio-button class="paragraph mt-1" [value]="true">
                            Yes
                        </mat-radio-button>
                        <mat-radio-button class="paragraph mt-1" [value]="false">
                            No
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div [hidden]="experienceInMarketResearch != true">
                    <div class="paragraph bold">
                        <span class="burnt-red">|</span>
                        In which types of developments do you specialise to do feasibility/viability studies?
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div *ngFor="let option of this.developmentSpeciality; let index = i">
                                <label class="checkbox-container paragraph m-1">
                                    <span class="paragraph">
                                        {{ option.value }}
                                        <span
                                            [ngClass]="{'specify-text': userForm.planningApplicationsExperienceAndKnowledgeOther?.length == 0}"
                                            *ngIf="developmentTypeOtherCheck(option)">
                                            - Please specify
                                        </span>
                                    </span>
                                    <input type="checkbox" [(ngModel)]="option.isChecked"
                                        [name]="planningApplicationsExperienceAndKnowledgeOptions"
                                        #planningApplicationsExperienceAndKnowledgeOptions="ngModel"
                                        (change)="selectedDevelopmentType(option)">
                                    <span class="checkmark"></span>
                                </label>
                                <div class="mt-2" *ngIf="developmentTypeOtherCheck(option)">
                                    <div class="row mt-2">
                                        <div class="col-10">
                                            <input name="planningApplicationsExperienceAndKnowledgeOther" type="text"
                                                class="paragraph mat-input-section mat-input-section-other"
                                                [(ngModel)]="userForm.urbanPlanner.companyDetail.developmentSpecialityOther"
                                                required #planningApplicationsExperienceAndKnowledgeOther="ngModel">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="submission-form">
                <p class="paragraph top-spacing">
                    <strong>
                        Details of a support staff member we may contact regarding <u>administrative</u> queries:
                    </strong>
                </p>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Name:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" class="paragraph not-required" name="administrativeName"
                            [(ngModel)]="userForm.urbanPlanner.companyDetail.contactDetail.name" #name="ngModel">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Surname:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" class="paragraph not-required" name="administrativeSurname"
                            [(ngModel)]="userForm.urbanPlanner.companyDetail.contactDetail.surname" #surname="ngModel">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Position:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="townPlanningPosition" class="paragraph not-required"
                            [(ngModel)]="userForm.urbanPlanner.companyDetail.contactDetail.position">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Landline:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="administrativeLandline" class="paragraph not-required"
                            [(ngModel)]="userForm.urbanPlanner.companyDetail.contactDetail.landLine">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">Mobile:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="administrativePhoneNumber" class="paragraph not-required"
                            [(ngModel)]="userForm.urbanPlanner.companyDetail.contactDetail.mobile"
                            #phoneNumber="ngModel" minlength="10">
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <label class="paragraph">
                            Email Address:
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="administrativeEmailAddress" class="paragraph not-required"
                            [(ngModel)]="userForm.urbanPlanner.companyDetail.contactDetail.emailAddress"
                            #emailAddress="ngModel" [pattern]="emailPattern">
                    </div>
                </div>
            </section>
            <div class="row text-center">
                <div class="col-12">
                    <button id="btnSaveFinal" class="button heading-4 top-spacing" [disabled]="informationForm.invalid"
                        (click)="submitForm()">
                        SUBMIT
                    </button>
                </div>
            </div>
        </div>
    </section>
</form>