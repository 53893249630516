import { GlobalMethods } from 'src/app/common/global-methods';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estate-agent-agreement',
  templateUrl: './estate-agent-agreement.component.html',
  styleUrls: ['./estate-agent-agreement.component.scss']
})
export class EstateAgentAgreementComponent implements OnInit {

  userType: string = 'estate-agent';

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void { }

  proceed() {
    this.route.queryParams.subscribe(params => {
      const navigationExtras = {
        queryParams: { ...params }
      };

      this.router.navigate(['/register'], navigationExtras);
      GlobalMethods.scrollToTop();
    });
  }
}
