<main *ngIf="userType == 'estate-agent'" class="main-container estate-agent">
    <div class="content">
        <div class="col-12 text-center">
            <div class="heading-1 mt-3">
                OPPORTUNITY FOR BROKER/AGENT
            </div>
        </div>
        <div class="col-12 mt-4 paragraph">
            <p>
                Welcome to <b>Property Matrix</b>, the leading digital platform for commercial and development
                properties, designed to empower <b>commercial brokers and estate agents</b> through collaboration with
                and support by a national network of:
            </p>
        </div>
        <div class="col-12 paragraph">
            <ul class="p-2">
                <li>
                    <b>Urban planners</b>
                    - Provide a planning report for every property listed. Click to view a sample of our Level 2 report.
                </li>
                <li>
                    <b>Architectural technologists</b>
                    - Obtain approved building plans where required to ensure availability when an OTP is received.
                </li>
                <li>
                    <b>Property valuers</b>
                    - Available for property valuations and assisting with value assessments.
                </li>
                <li>
                    <b>Urban economists</b>
                    - Conduct feasibility and market demand studies, evaluating the potential success of a project
                    (projected ROI).
                </li>
                <li>
                    <b>Bond originators</b>
                    - Experts in commercial property finance to assist with pre-approvals and securing the best possible
                    deal.
                </li>
                <li>
                    <b>Conveyancers</b>
                    - A legal team specializing in commercial and development property advice and transfers.
                </li>
            </ul>
        </div>
        <div class="col-12 paragraph">
            <p>
                Our first phase operations span the metropolitan cities of Tshwane Johannesburg, Ekurhuleni, Cape Town,
                and eThekwini. Commercial brokers, Property Practitioners and agencies are invited to join our network.
            </p>
        </div>
        <div class="col-12 paragraph mt-4">
            <span class="bold">BENEFITS FOR REGISTERED BROKERS/AGENTS:</span>
            <ul class="p-2">
                <li>
                    Access to professional planning reports, verifying zoning rights, permitted land uses, development
                    constraints (e.g. servitudes) and ways to extend, enhance (or develop) the property.
                </li>
                <li>
                    A robust professional support network.
                </li>
                <li>
                    Reliable, data-backed information.
                </li>
                <li>
                    Tools to secure exclusive mandates.
                </li>
                <li>
                    Leads from sellers contacting Property Matrix.
                </li>
                <li>
                    Access to our platform to list a wide range of property types (business, beauty, retail, recreation,
                    education, medical, etc.).
                </li>
                <li>
                    A unique search engine, designed for developers, investors and commercial buyers.
                </li>
                <li>
                    Extended training courses, empowering brokers and agents to provide sound advice.
                </li>
                <li>
                    Buyer tracing (Phase 2 launch).
                </li>
            </ul>
        </div>
        <div class="col-12 paragraph center-content mt-4">
            <p>
                Make use of our innovative marketing approach to expand on and to expedite your property sales.
                For more information, please proceed below.
            </p>
        </div>
        <div class="button-container mt-3">
            <button mat-button (click)="proceed()" class="button-proceed">PROCEED</button>
        </div>
    </div>
</main>

<main *ngIf="userType == 'urban-planner'" class="main-container urban-planner">
    <div class="content">
        <div class="col-12 text-center">
            <div class="heading-1 mt-3">
                OPPORTUNITY FOR SACPLAN-REGISTERED PLANNERS
            </div>
        </div>
        <div class="col-12 mt-4 paragraph">
            <p>
                Thank you for visiting our platform. <b>Property Matrix</b> is a national network of professional town
                planners, estate agents, property valuers, urban economists, bond originators, and conveyancers. Our
                operations cover Tshwane, Johannesburg, Ekurhuleni, Cape Town, and eThekwini.
            </p>
            <p>
                We facilitate connections between planners operating in these areas and clients in need of land use
                management (LUMS) expertise. Our process involves generating an initial planning report for a property,
                which is then sent to an 'associate planner.' This associate planner reviews, expands on, and approves
                the report.
            </p>
            <p>
                This report serves as a resource for <b>Property Matrix</b> clients, offering insights into the
                property's existing land use rights and, importantly, its development, expansion, or enhancement
                potential. The report also includes recommendations for necessary planning applications and estimated
                timeframes for realising this potential. We commit to directing all statutory planning tasks stemming
                from such a property report to the appropriate associate planner.
            </p>
            <p>
                To partner as an 'associate planner' with <b>Property Matrix</b>, you must be a SACPLAN registered
                professional town planner and hold a leadership role in an established consulting town planning firm,
                such as a director, partner, or senior planner.
            </p>
        </div>
        <div class="col-12 mt-4 paragraph">
            <div class="heading-4">
                Professional Details
            </div>
            <div class="sacplan-input-container row">
                <div class="col-3">
                    <label for="sacplan" class="sacplan-input-label">
                        Full SACPLAN registration number
                    </label>
                </div>
                <div class="col-7">
                    <input type="text" class="form-control sacplan-input" id="sacplan" name="sacplan" required
                        [formControl]="sacplanRegistrationNumber">
                    <div *ngIf="showSacPlanError" class="sacplan-error-text">
                        <div *ngIf="sacplanRegistrationNumber.errors?.required">
                            SACPLAN registration number is required
                        </div>
                        <div *ngIf="sacplanRegistrationNumber.errors?.pattern">
                            Invalid SACPLAN registration number format
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mt-4 paragraph">
            <div class="heading-5">
                <span class="font-weight-bold">
                    Your SACPLAN registration type:
                </span>
            </div>
            <mat-radio-group class="sacplan-registration-types">
                <mat-radio-button class="paragraph" *ngFor="let type of sacplanRegistrationTypes" [value]="type.id"
                    (change)="selectSacplanRegistrationType(type.id)">
                    {{ type.value }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div *ngIf="isProfessionalPlanner == false" class="col-12 mt-4 paragraph">
            <p>
                <b>Please note:</b> Only professional, senior planners - registered with SACPLAN - can
                partner with Property Matrix, and sign off on our planning reports.
            </p>
        </div>
        <div class="button-container mt-3">
            <button mat-button (click)="proceed()" class="button-proceed">PROCEED</button>
        </div>
    </div>
</main>