import { GlobalMethods } from 'src/app/common/global-methods';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration-step',
  templateUrl: './registration-step.component.html',
  styleUrls: ['./registration-step.component.scss']
})
export class RegistrationStepComponent implements OnInit {

  userType: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.userType = params['userType'];
    });

    GlobalMethods.scrollToTop();
  }

  proceed() {
    if (this.userType === 'estate-agent') {
      this.router.navigateByUrl('/estate-agent-register-form');
    } else if (this.userType === 'urban-planner') {
      this.router.navigateByUrl('/planner-register-form');
    }
  }
}
