<main class="main-container">

    <section *ngIf="userType == 'estate-agent'" class="content">
        <div class="content-padding">
            <div class="container mt-4">
                <div class="row">
                    <div class="col-12">
                        <h1 class="heading-1 text-center">
                            Registration: Brokers & Estate Agents
                        </h1>
                    </div>
                </div>
                <div class="paragraph text-center">
                    <p class="mt-2">
                        Congratulations on the smart decision to partner with <b>Property Matrix</b>.
                    </p>
                    <div class="bold mt-2">
                        Registration can be done through 4 easy steps:
                    </div>
                </div>
                <div class="row paragraph mt-5">
                    <div class="col-3 text-center numbers-right-border">
                        <div class="row">
                            <div class="col heading-3 bold">
                                STEP
                            </div>
                        </div>
                        <div class="row">
                            <div class="col numbers bold">
                                1
                            </div>
                        </div>
                        <div class="row">
                            <div class="col bold">
                                Provide work-related information
                            </div>
                        </div>
                    </div>
                    <div class="col-3 text-center numbers-right-border">
                        <div class="row">
                            <div class="col heading-3 bold">
                                STEP
                            </div>
                        </div>
                        <div class="row">
                            <div class="col numbers bold">
                                2
                            </div>
                        </div>
                        <div class="row">
                            <div class="col bold">
                                Provide personal information
                            </div>
                        </div>
                    </div>
                    <div class="col-3 text-center numbers-right-border">
                        <div class="row">
                            <div class="col heading-3 bold">
                                STEP
                            </div>
                        </div>
                        <div class="row">
                            <div class="col numbers bold">
                                3
                            </div>
                        </div>
                        <div class="row">
                            <div class="col bold">
                                Upload documents
                            </div>
                        </div>
                    </div>
                    <div class="col-3 text-center">
                        <div class="row">
                            <div class="col heading-3 bold">
                                STEP
                            </div>
                        </div>
                        <div class="row">
                            <div class="col numbers bold">
                                4
                            </div>
                        </div>
                        <div class="row">
                            <div class="col bold">
                                Contract Agreement
                            </div>
                        </div>
                    </div>
                </div>
                <div class="paragraph mt-5">
                    <p class="bold m-0">
                        For STEP 4, please have the following ready to submit in PDF format:
                    </p>
                    <ul class="list-items">
                        <li>Copy of your identification document.</li>
                        <li>Up to date individual PPRA Fidelity Fund Certificate.</li>
                        <li>A photo of yourself to be used by Property Matrix for marketing purposes.</li>
                    </ul>
                </div>
                <div class="paragraph mt-3">
                    <p class="bold m-0">
                        Where applicable:
                    </p>
                    <ul class="list-items">
                        <li>
                            Up to date company PPRA Fidelity Fund Certificate.
                        </li>
                        <li>
                            Company VAT certificate.
                        </li>
                        <li>
                            Certified copy(ies), of professional qualifications and/or designations awarded to you
                            (optional).
                        </li>
                        <li>
                            Signed Collaboration Agreement - Principals only. A copy can be downloaded as part of Step
                            3.
                        </li>
                    </ul>
                </div>
                <div class="paragraph mt-3">
                    <p>
                        <b>Please note:</b> You may provide a photo where the name and/or branding of your franchise is
                        visible - <b>Property Matrix</b> is inclusive in this regard.
                    </p>
                </div>
                <div class="text-center mt-5">
                    <button mat-button (click)="proceed()" class="button-proceed heading-4 uppercase">
                        Proceed
                    </button>
                </div>
            </div>
        </div>
    </section>

    <section *ngIf="userType == 'urban-planner'" class="content">
        <div class="content-padding">
            <div class="container mt-4">
                <div class="row">
                    <div class="col-12">
                        <h1 class="heading-1 text-center">
                            Registration For Planner
                        </h1>
                    </div>
                </div>
                <div class="paragraph text-center">
                    <div class="bold mt-2">
                        This form can be completed in 15-20 minutes, following 4 straightforward steps:
                    </div>
                </div>
                <div class="row paragraph mt-5">
                    <div class="col-3 text-center numbers-right-border">
                        <div class="row">
                            <div class="col heading-3 bold">
                                STEP
                            </div>
                        </div>
                        <div class="row">
                            <div class="col numbers bold">
                                1
                            </div>
                        </div>
                        <div class="row">
                            <div class="col bold">
                                Personal & company information
                            </div>
                        </div>
                    </div>
                    <div class="col-3 text-center numbers-right-border">
                        <div class="row">
                            <div class="col heading-3 bold">
                                STEP
                            </div>
                        </div>
                        <div class="row">
                            <div class="col numbers bold">
                                2
                            </div>
                        </div>
                        <div class="row">
                            <div class="col bold">
                                Details: extended professional network
                            </div>
                        </div>
                    </div>
                    <div class="col-3 text-center numbers-right-border">
                        <div class="row">
                            <div class="col heading-3 bold">
                                STEP
                            </div>
                        </div>
                        <div class="row">
                            <div class="col numbers bold">
                                3
                            </div>
                        </div>
                        <div class="row">
                            <div class="col bold">
                                Submission of documents
                            </div>
                        </div>
                    </div>
                    <div class="col-3 text-center">
                        <div class="row">
                            <div class="col heading-3 bold">
                                STEP
                            </div>
                        </div>
                        <div class="row">
                            <div class="col numbers bold">
                                4
                            </div>
                        </div>
                        <div class="row">
                            <div class="col bold">
                                Confirmation email with SLA contract
                            </div>
                        </div>
                    </div>
                </div>
                <div class="paragraph mt-5">
                    <p class="bold m-0">
                        For STEP 3, please have the following ready to submit in PDF format:
                    </p>
                    <ul class="list-items">
                        <li>Copy of your identification document</li>
                        <li>Updated SACPLAN registration certificate</li>
                        <li>Certified copy of your town and regional planning degree certificate</li>
                        <li>A photo of yourself (for office & admin purposes)</li>
                        <li>Company logo for marketing purposes</li>
                        <li>Certified copy(ies) of additional professional qualifications/designations (optional)</li>
                    </ul>
                </div>
                <div class="text-center mt-5">
                    <button mat-button (click)="proceed()" class="button-proceed heading-4 uppercase">
                        Proceed
                    </button>
                </div>
            </div>
        </div>
    </section>
</main>