import { GlobalMethods } from 'src/app/common/global-methods';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estate-agent',
  templateUrl: './estate-agent.component.html',
  styleUrls: ['./estate-agent.component.scss']
})
export class EstateAgentComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void { }

  proceed() {
    this.route.queryParams.subscribe(params => {
      const navigationExtras = {
        queryParams: { ...params }
      };

      this.router.navigate(['/estate-agent-agreement'], navigationExtras);
      GlobalMethods.scrollToTop();
    });
  }
}
