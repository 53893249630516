<main class="main-container">
    <div class="row paragraph mt-3">
        <div class="col-12 heading-2">
            Step 2: Work-Related Information
        </div>
    </div>
    <form [formGroup]="companyInfoForm" (ngSubmit)="onSubmit()">
        <div class="row paragraph mt-5">
            <div class="col-4">
                <label for="individualFidelityFundCertificateNumber">Your PPRA individual FFC number:</label>
            </div>
            <div class="col-8">
                <input required id="individualFidelityFundCertificateNumber"
                    formControlName="individualFidelityFundCertificateNumber" type="text">
            </div>
        </div>
        <div *ngIf="isPrincipal">
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="companyFidelityFundCertificateNumber">Your PPRA company FFC number:</label>
                </div>
                <div class="col-8">
                    <input required id="companyFidelityFundCertificateNumber"
                        formControlName="companyFidelityFundCertificateNumber" type="text">
                </div>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="col-4">
                <label for="individualReferenceNumber">Your individual reference number:</label>
            </div>
            <div class="col-8">
                <input id="individualReferenceNumber" formControlName="individualReferenceNumber" type="text">
            </div>
        </div>
        <div *ngIf="!isPrincipal">
            <div class="row paragraph mt-5">
                <div class="col-12 bold">
                    Confirm the following details <span class="coral-text">of your principal</span>:
                </div>
                <div class="col-12 mt-2">
                    Please note: if the information below is incorrect, the link used to register is not generated by
                    your principal.
                </div>
            </div>
            <div class="row paragraph mt-4">
                <div class="col-4">
                    <label for="principalName">Name:</label>
                </div>
                <div class="col-8">
                    <input required id="principalName" formControlName="principalName" type="text">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="principalSurname">Surname:</label>
                </div>
                <div class="col-8">
                    <input required id="principalSurname" formControlName="principalSurname" type="text">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="principalIdNumber">ID/passport number:</label>
                </div>
                <div class="col-8">
                    <input required id="principalIdNumber" formControlName="principalIdNumber" type="text">
                </div>
            </div>
        </div>
        <div *ngIf="isPrincipal">
            <div class="row paragraph mt-5">
                <div class="bold">
                    Please select:
                </div>
            </div>
            <div class="row paragraph mt-3">
                <label>
                    <input type="radio" formControlName="isAffiliatedWithAgency" [value]="true">
                    I am affiliated with a franchised estate agency
                </label>
                <label>
                    <input type="radio" formControlName="isAffiliatedWithAgency" [value]="false">
                    I am an independent estate agent/business, not linked to any franchise
                </label>
            </div>
            <div *ngIf="isAffiliatedWithAgency">
                <div class="row paragraph mt-3">
                    <div class="col-4">
                        <label for="agencyName">Name of the agency</label>
                    </div>
                    <div class="col-8">
                        <input required id="agencyName" formControlName="agencyName" type="text">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-4">
                        <label for="agencyBranch">Name of the branch you are operating from</label>
                    </div>
                    <div class="col-8">
                        <input required id="agencyBranch" formControlName="agencyBranch" type="text">
                    </div>
                </div>
            </div>
            <div *ngIf="!isAffiliatedWithAgency">
                <div class="row paragraph mt-3">
                    <div class="col-4">
                        <label for="firmName">Firm name</label>
                    </div>
                    <div class="col-8">
                        <input required id="firmName" formControlName="firmName" type="text">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-4">
                        <label for="tradingName">Trading name</label>
                    </div>
                    <div class="col-8">
                        <input required id="tradingName" formControlName="tradingName" type="text">
                    </div>
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="firmPinNumber">Firm PPRA Pin nr:</label>
                </div>
                <div class="col-8">
                    <input required id="firmPinNumber" formControlName="firmPinNumber" type="text">
                </div>
            </div>
            <div class="row paragraph mt-5">
                <div class="bold">
                    The physical address of your office:
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="unitNumber">Building/unit no:</label>
                </div>
                <div class="col-8">
                    <input id="unitNumber" formControlName="unitNumber" type="text">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="complexName">Complex/Building:</label>
                </div>
                <div class="col-8">
                    <input id="complexName" formControlName="complexName" type="text">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="streetNumber">Street no:</label>
                </div>
                <div class="col-8">
                    <input required id="streetNumber" formControlName="streetNumber" type="text">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="streetName">Street name:</label>
                </div>
                <div class="col-8">
                    <input required id="streetName" formControlName="streetName" type="text">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="suburb">Suburb:</label>
                </div>
                <div class="col-8">
                    <input required id="suburb" formControlName="suburb" type="text">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="city">City:</label>
                </div>
                <div class="col-8">
                    <input required id="city" formControlName="city" type="text">
                </div>
            </div>
            <div class="row paragraph mt-5">
                <h4>Bank account details</h4>
            </div>
            <div class="row paragraph mt-3">
                <div class="bold">
                    Account where your commission - with the consent of your franchise (if applicable) - can be paid
                    into.
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="accountHolderName">Account holder:</label>
                </div>
                <div class="col-8">
                    <input required id="accountHolderName" formControlName="accountHolderName" type="text">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="bankName">Bank:</label>
                </div>
                <div class="col-8">
                    <input required id="bankName" formControlName="bankName" type="text">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="branchName">Branch name:</label>
                </div>
                <div class="col-8">
                    <input id="branchName" formControlName="branchName" type="text">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="branchNumber">Branch number:</label>
                </div>
                <div class="col-8">
                    <input required id="branchNumber" formControlName="branchNumber" type="text">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="accountType">Type of account:</label>
                </div>
                <div class="col-8">
                    <input required id="accountType" formControlName="accountType" type="text">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="accountNumber">Account number:</label>
                </div>
                <div class="col-8">
                    <input required id="accountNumber" formControlName="accountNumber" type="text">
                </div>
            </div>
            <div class="row paragraph mt-3">
                <div class="col-4">
                    <label for="vatNumber">If registered for VAT, your firm's VAT number:</label>
                </div>
                <div class="col-8">
                    <input required id="vatNumber" formControlName="vatNumber" type="text">
                </div>
            </div>
        </div>
        <div class="row paragraph mt-5">
            <div class="text-center">
                <button class="button" type="submit" [disabled]="companyInfoForm.invalid">SUBMIT</button>
            </div>
        </div>
    </form>
</main>