import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-estate-agent-introduction',
  templateUrl: './estate-agent-introduction.component.html',
  styleUrls: ['./estate-agent-introduction.component.scss']
})
export class EstateAgentIntroductionComponent implements OnInit {

  videoLoaded: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.loadVideo();
  }

  loadVideo(): void {
    const videoElement: HTMLVideoElement = document.createElement('video');
    videoElement.src = 'assets/videos/stock-video.mp4';

    videoElement.onloadeddata = () => {
      this.videoLoaded = true;
    };

    videoElement.onerror = () => {
      this.videoLoaded = false;
    };
  }

  navigateToRegistration(): void {
    this.router.navigateByUrl('/estate-agent-registration');
  }
}
