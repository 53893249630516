import { EstateAgentRegistrationStateService } from 'src/app/shared/services/registration/estate-agent-registration-state/estate-agent-registration-state.service';
import { GlobalMethods } from 'src/app/common/global-methods';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-estate-agent-processes',
  templateUrl: './estate-agent-processes.component.html',
  styleUrls: ['./estate-agent-processes.component.scss']
})
export class EstateAgentProcessesComponent implements OnInit {

  constructor(
    private router: Router,
    private _estateAgentRegistrationStateService: EstateAgentRegistrationStateService
  ) { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
  }

  proceed(isPrincipal: boolean) {
    this._estateAgentRegistrationStateService.setIsPrincipal(isPrincipal);
    this.router.navigateByUrl('/estate-agent-register-form');
    GlobalMethods.scrollToTop();
  }

  proceedAsPrincipal() {
    this.proceed(true);
  }

  proceedAsAgent() {
    this.proceed(false);
  }
}
