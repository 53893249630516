<main class="main-container">
    <div class="content-container">
        <div class="content paragraph">
            <span class="title">COLLABORATION MODEL</span>
            <div class="center-content">
                <b>
                    Before registering, please review the key points of our agreement.
                </b>
            </div>
            <div class="center-content">
                <b>
                    Business owners (principals) will receive a formal contract with the full terms.
                </b>
            </div>
            <div class="col-12 paragraph mt-4">
                <span class="bold">SUMMARY OF THE PROPERTY MATRIX MODEL:</span>
                <ul class="p-2">
                    <li>
                        <b>Registration:</b>
                        Principals need to register first, after which they will be provided with a link to share with
                        brokers/agents in their franchise.
                    </li>
                    <li>
                        <b>Access to platform:</b>
                        All nominated commercial brokers/agents will receive login details once a signed collaboration
                        agreement is received from their principal/business owner.
                    </li>
                    <li>
                        <b>Training guidelines:</b>
                        Access to online videos, explaining the Property Matrix model and workflow will be provided.
                    </li>
                    <li>
                        <b>Seller costs:</b>
                        Sellers incur no costs; Property Matrix covers the title deed, property investigation, detailed
                        planning report and marketing expenses.
                    </li>
                    <li>
                        <b>Risk-based services:</b>
                        No registration, planning report, or advertisement fees payable by brokers to list a property on
                        the platform. Property Matrix partners with brokers, providing them with professional services
                        and data to secure exclusive mandates and achieve additional, faster sales. Property Matrix acts
                        as a service provider, and our fee forms part of the commission payout by the transferring
                        attorney. The fee structure will be provided during training.
                    </li>
                    <li>
                        <b>Fee structure:</b>
                        Property Matrix acts as a service provider, and our value-based fee is only payable if
                        commission is earned. Payout is done by the transferring attorney. The fee structure will be
                        provided during training.
                    </li>
                </ul>
            </div>
            <div class="col-12 paragraph mt-4">
                <span class="bold">PROPERTY LISTINGS:</span>
                <ul class="p-2">
                    <li>
                        <b>Value assessment:</b>
                        Brokers/Agents are required to visit the property, complete a questionnaire and conduct a
                        comparative market analysis (if required) to propose a market-related asking price. Insights
                        into market trends in your area are integral to our model.
                    </li>
                    <li>
                        <b>Planning report:</b>
                        Finalized upon receiving a signed exclusive mandate with a realistic, market-related asking
                        price.
                    </li>
                    <li>
                        <b>Conveyancing report:</b>
                        Our legal team will order the title deed and draft a basic conveyancing report upon receiving a
                        signed exclusive mandate and upon the Property Matrix legal team being confirmed as
                        conveyancers. This service is optional for sellers and brokers.
                    </li>
                    <li>
                        <b>Advertising:</b>
                        The property will be listed on our website with free access to the planning report for all
                        users. All Broker/Agent related queries will be directed to you.
                    </li>
                    <li>
                        <b>Legal and mortgage support:</b>
                        Conveyancers are available for legal advice and the transfer process and bond originators for
                        mortgage assistance.
                    </li>
                    <li>
                        <b>Professional fee:</b>
                        Payable only upon a successful sale of your listed property and leads referred to you by
                        Property Matrix. Our associated fee schedules will be included in our collaboration agreement
                        and presented during training.
                    </li>
                    <li>
                        <b>Valuation support:</b>
                        Desktop or formal valuations by registered professional valuers will be available if needed.
                    </li>
                </ul>
            </div>
            <div class="col-12 paragraph mt-4">
                <span class="bold">Available in a future launch:</span>
                <ul class="p-2">
                    <li>
                        <b>Architectural support:</b>
                        Assistance in verifying approved building plans.
                    </li>
                    <li>
                        <b>Direct seller listings:</b>
                        Property Matrix to refer direct listings (leads) to a partnering broker/agent to visit the site,
                        conduct a value assessment and sign an exclusive mandate.
                    </li>
                </ul>
            </div>
            <div class="col-12 paragraph mt-4">
                <p class="bold m-0">JOIN OUR NETWORK:</p>
                <p>
                    Proceed below to partner with us. Registration takes about 15-20 minutes.
                </p>
            </div>
            <div class="button-container">
                <button mat-button (click)="proceed()" class="button-proceed heading-4">PROCEED</button>
            </div>
        </div>
    </div>
</main>