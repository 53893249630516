<div class="background-image container-fluid">
    <section class="content">
        <div class="center-page">
            <div class="heading-1 text-center pb-5">REGISTER AS:</div>
            <div class="row justify-center">
                <div class="col-md-6 pt-3">
                    <div class="choice-block">
                        <div class="choice-block-content" (click)="navigateWithUserType('/register', 'website-user')">
                            <div class="row">
                                <div class="col-md-5 heading-3 block-text website-user-block">
                                    WEBSITE USER
                                </div>
                                <div class="col-md-7 paragraph bold list-items">
                                    <div class="list-item">
                                        Buying property
                                    </div>
                                    <div class="list-item">
                                        Selling your property
                                    </div>
                                    <div class="list-item">
                                        Subscribe to our database
                                    </div>
                                    <div class="list-item">
                                        Order additional services
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 pt-3">
                    <div class="choice-block">
                        <div class="choice-block-content heading-3 block-text"
                            (click)="navigateWithUserType('/estate-agent-option', 'estate-agent')">
                            BROKER / AGENT
                        </div>
                    </div>
                </div>
                <div class="col-md-3 pt-3">
                    <div class="choice-block">
                        <div class="choice-block-content heading-3 block-text"
                            (click)="navigateWithUserType('/planner-register', 'urban-planner')">
                            URBAN PLANNER
                        </div>
                    </div>
                </div>
                <div class="col-md-3 pt-3">
                    <div class="choice-block">
                        <div class="choice-block-content heading-3 block-text"
                            (click)="navigateWithUserType('/conveyancer-option', 'conveyancer')">
                            CONVEYANCER
                        </div>
                    </div>
                </div>
                <div class="col-md-3 pt-3">
                    <div class="choice-block">
                        <div class="choice-block-content heading-3 block-text"
                            (click)="navigateWithUserType('/valuer-registration', 'property-valuer')">
                            PROPERTY VALUER
                        </div>
                    </div>
                </div>
                <div class="col-md-3 pt-3">
                    <div class="choice-block">
                        <div class="choice-block-content heading-3 block-text"
                            (click)="navigateWithUserType('/bond-originator', 'bond-originator')">
                            BOND ORIGINATOR
                        </div>
                    </div>
                </div>
                <div class="col-md-3 pt-3">
                    <div class="choice-block">
                        <div class="choice-block-content heading-3 block-text"
                            (click)="navigateWithUserType('/architectural-technologist', 'architectural-technologist')">
                            ARCHITECTURAL TECHNOLOGIST
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center pt-5">
                <span class="paragraph large bold">
                    You can also act as a website user if registered as part of
                    the Property Matrix network.
                </span>
            </div>
        </div>
    </section>
</div>