import { EstateAgentDto, EstateAgentPersonalInformationDto, EstateAgentCompanyInformationDto, EstateAgentDocumentDto } from 'src/app/services/property-matrixV2/models';
import { EstateAgentRegistrationStateService } from 'src/app/shared/services/registration/estate-agent-registration-state/estate-agent-registration-state.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { RegistrationService } from 'src/app/services/property-matrixV2/services';
import { GlobalMethods } from 'src/app/common/global-methods';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-estate-agent-register-form',
  templateUrl: './estate-agent-register-form.component.html',
  styleUrls: ['./estate-agent-register-form.component.scss']
})
export class EstateAgentRegisterFormComponent implements OnInit {

  pageNumber: number = 1;
  visitedPages: number[] = [1];
  loading: boolean = false;
  isProgressLockEnabled: boolean = true;

  personalInformation!: EstateAgentPersonalInformationDto;
  companyInformation!: EstateAgentCompanyInformationDto;
  documents: EstateAgentDocumentDto[] = [];

  steps = [
    { label: 'PERSONAL INFORMATION', link: 1 },
    { label: 'COMPANY INFORMATION', link: 2 },
    { label: 'SUBMIT DOCUMENTS', link: 3 },
    { label: 'SIGN CONTRACT', link: 4 }
  ];

  constructor(
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _notificationService: NotificationService,
    private _registrationService: RegistrationService,
    private _estateAgentRegistrationStateService: EstateAgentRegistrationStateService
  ) { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
    window.addEventListener('beforeunload', this.confirmPageExit);
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.confirmPageExit);
  }

  confirmPageExit(event: BeforeUnloadEvent): string {
    event.preventDefault();
    return '';
  }

  unlockNextPage() {
    if (!this.visitedPages.includes(this.pageNumber + 1)) {
      this.visitedPages.push(this.pageNumber + 1);
    }
  }

  selectPage(pageNumber: number) {
    if (this.isProgressLockEnabled && !this.visitedPages.includes(pageNumber)) {
      this._snackBar.open('Please submit the form to proceed to the next step.', 'Close', {
        duration: 3000
      });
      return;
    }
    this.pageNumber = pageNumber;
    console.log('Current Page Number: ' + this.pageNumber);
    GlobalMethods.scrollToTop();
  }

  changePage(page: number) {
    if (this.isProgressLockEnabled && !this.visitedPages.includes(page)) {
      return;
    }
    this.pageNumber = page;
    GlobalMethods.scrollToTop();
  }

  onContractSigned(): void {
    this.registerEstateAgent();
  }

  registerEstateAgent() {
    this.loading = true;
    const formState: EstateAgentDto = this._estateAgentRegistrationStateService.getFormState();

    this._registrationService.apiV1RegistrationRegisterEstateAgentPost({
      body: formState
    }).subscribe({
      next: () => {
        this._notificationService.showSuccessMessage('Success', 'Agent registration was successful.');
        this._router.navigateByUrl('');
        this.loading = false;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'An error occurred while registering agent.');
        this.loading = false;
      }
    });
  }

  onFormSubmitted() {
    this.unlockNextPage();
    this.changePage(this.pageNumber + 1);
  }
}
