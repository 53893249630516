import { GlobalMethods } from 'src/app/common/global-methods';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-estate-agent-registration',
  templateUrl: './estate-agent-registration.component.html',
  styleUrls: ['./estate-agent-registration.component.scss']
})
export class EstateAgentRegistrationComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
  }

  proceed() {
    this.router.navigateByUrl('/estate-agent-register-form');
  }
}
