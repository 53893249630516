<main class="main-container">
    <div class="row paragraph mt-3">
        <div class="col-12 heading-2">
            Step 1: Personal Information
        </div>
    </div>
    <form [formGroup]="estateAgentForm" (ngSubmit)="onSubmit()">
        <div class="row paragraph mt-4">
            <div class="bold">
                <span class="burnt-red">|</span>
                According to your Fidelity Fund Certificate, what is your status as an estate agent/commercial broker?
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div *ngFor="let status of estateAgentStatuses">
                <label>
                    <input type="radio" formControlName="status" [value]="status">
                    {{ status.value }}
                </label>
            </div>
        </div>
        <div class="row paragraph mt-4">
            <div class="col-12">
                <div class="bold">
                    <span class="burnt-red">|</span>
                    Indicate the metro, region(s) and suburbs you commit to operate in on the map:
                </div>
                <div class="mt-2">
                    <b class="coral-text">Important: </b>
                    Only indicate areas where you know the market and trends very well. Your expertise in providing us
                    with market related selling prices will be relied upon.
                </div>
            </div>
        </div>
        <div class="row paragraph mt-4">
            <div class="col-12 center-content button-group">
                <div class="buttons-container">
                    <button type="button" [class.btn-selected]="selectedButton === 'Metro'"
                        (click)="selectButton('Metro')" class="btn-default">
                        <label>Metro</label>
                    </button>
                    <button type="button" [class.btn-selected]="selectedButton === 'Region'"
                        (click)="selectButton('Region')" class="btn-default">
                        <label>Region</label>
                    </button>
                    <button type="button" [class.btn-selected]="selectedButton === 'Suburb'"
                        (click)="selectButton('Suburb')" class="btn-default">
                        <label>Suburb</label>
                    </button>
                </div>
            </div>
        </div>
        <div class="row paragraph mt-3">
            <div class="center-content map-placeholder">
                * Insert Map Here *
            </div>
        </div>
        <div class="row paragraph mt-4">
            <div formArrayName="sectors">
                <div class="bold">
                    <span class="burnt-red">|</span>
                    In which property sectors do you <u>specialize</u> (have extensive experience and knowledge)?
                </div>
                <div *ngFor="let sector of propertySectors; let i = index">
                    <label>
                        <input type="checkbox" [formControlName]="i">
                        {{ sector.value }}
                    </label>
                </div>
            </div>
            <div *ngIf="isOtherSectorChecked()">
                <input class="mat-input-section" id="otherSectors" formControlName="otherSectors"
                    placeholder="Please specify" type="text">
            </div>
        </div>
        <div class="row paragraph mt-4">
            <div class="col-12">
                <div class="bold">
                    How long have you been operating as a <u>registered</u> Estate Agent?
                </div>
            </div>
            <div class="col-12">
                <div class="row align-bottom">
                    <div class="col-auto years-label">
                        Years:
                    </div>
                    <div class="col-5">
                        <input class="mat-input-section" id="yearsInOperation" formControlName="yearsInOperation"
                            type="number">
                        <div *ngIf="estateAgentForm.get('yearsInOperation').invalid && estateAgentForm.get('yearsInOperation').touched"
                            class="error-message">
                            <small *ngIf="estateAgentForm.get('yearsInOperation').errors?.min">
                                Years cannot be less than 0.
                            </small>
                            <small *ngIf="estateAgentForm.get('yearsInOperation').errors?.max">
                                Years cannot exceed 100.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row paragraph mt-5">
            <div class="text-center">
                <button class="button" type="submit" [disabled]="estateAgentForm.invalid">SUBMIT</button>
            </div>
        </div>
    </form>
</main>