import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { SacPlanRegistrationDto } from 'src/app/services/property-matrixV2/models';
import { LookupService } from 'src/app/services/property-matrixV2/services';
import { GlobalMethods } from 'src/app/common/global-methods';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-opportunity-step',
  templateUrl: './opportunity-step.component.html',
  styleUrls: ['./opportunity-step.component.scss']
})
export class OpportunityStepComponent implements OnInit {

  userType: string | null = null;
  isProfessionalPlanner: boolean = null;
  showSacplanError: boolean = false;

  sacplanRegistrationNumber: FormControl;
  selectedSacplanRegistrationType: SacPlanRegistrationDto;
  sacplanRegistrationTypes: SacPlanRegistrationDto[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private lookupService: LookupService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.userType = params['userType'];
    });

    if (this.userType === 'urban-planner') {
      this.loadRegistrationTypes();

      this.sacplanRegistrationNumber = new FormControl('', [
        Validators.required,
        Validators.pattern(/^[A-Z]\d{4}\/(19|20)\d{2}$/)
      ]);

      this.sacplanRegistrationNumber.statusChanges.subscribe(status => {
        this.showSacplanError = status === 'INVALID' && (this.sacplanRegistrationNumber.dirty || this.sacplanRegistrationNumber.touched);
      });
    }

    GlobalMethods.scrollToTop();
  }

  private loadRegistrationTypes(): void {
    this.lookupService.apiV1LookupGetUrbanPlannerRegistrationTypesGet().subscribe({
      next: (response: SacPlanRegistrationDto[]) => {
        this.sacplanRegistrationTypes = response;
        this.sacplanRegistrationTypes.sort((a, b) => a.intValue - b.intValue);
      },
      error: (_error) => {
        this.notificationService.showErrorMessage('Error', 'Failed to load registration types.');
      }
    });
  }

  selectSacplanRegistrationType(event: any) {
    this.selectedSacplanRegistrationType = this.sacplanRegistrationTypes.find(x => x.id == event);
    if (this.selectedSacplanRegistrationType.value == 'Professional Planner') {
      this.isProfessionalPlanner = true;
    } else {
      this.isProfessionalPlanner = false;
    }
  }

  proceed() {
    this.route.queryParams.subscribe(params => {
      const navigationExtras = {
        queryParams: { ...params }
      };

      if (this.userType == 'estate agent') {
        this.router.navigate(['/estate-agent-agreement'], navigationExtras);
      } else if (this.userType == 'urban-planner') {
        if (this.selectedSacplanRegistrationType == null || this.sacplanRegistrationNumber.value == '') {
          GlobalMethods.tinyErrorAlert('Error', 'Please ensure all required fields are completed.');
          return;
        } else if (this.isProfessionalPlanner == false) {
          GlobalMethods.tinyErrorAlert('Error', 'Sacplan registration must be professional.');
          return;
        } else {
          this.router.navigate(['/collaboration-agreement'], navigationExtras);
        }
      }

      GlobalMethods.scrollToTop();
    });
  }
}
