import { AddressDto, ContactDetailDto, DevelopmentSpecialityDto, LandDevelopmentTypeDto, MunicipalCouncilDto, OrganizationInvoiceDetailsDto, PropertyDetailDto, SacPlanRegistrationDto, UploadFileDto, UserDto } from 'src/app/services/property-matrixV2/models';
import { LookupService, MapsService, SellMyPropertyService } from 'src/app/services/property-matrixV2/services';
import { RegistrationService } from 'src/app/services/property-matrixV2/custom-services/registration.service';
import { WizardDataService } from 'src/app/services/property-matrixV2/custom-services/wizard-data.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { IndividualInvoiceDetailsVm } from 'src/app/services/property-matrix/models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalConstants } from 'src/app/common/global-constants';
import { Component, EventEmitter, Output } from '@angular/core';
import { GlobalMethods } from 'src/app/common/global-methods';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

export interface PlannerPropertyDetailsDialogData {
  schemeData: any[];
}

@Component({
  selector: 'app-planner-register-form-information',
  templateUrl: './planner-register-form-information.component.html',
  styleUrls: ['./planner-register-form-information.component.scss', '../../../../../../../css/2-modules/_forms.scss']
})
export class PlannerRegisterFormInformationComponent {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() cadastreEvent = new EventEmitter<UploadFileDto>();

  loadingCadaster: boolean = false;
  isPropertySelected: boolean = false;
  experienceInMarketResearch: boolean = false;

  selectedButton: number = 0;
  confirmCorrectAddress: number = 2;
  confirmPropertyDetail: number = 0;
  selectedBoundaryButton: number = 0;
  confirmPropertyBoundary: number = 0;

  riskscapeInfo: any = [];
  cadasterPicture: any = 1;
  riskscapeAddress: any = {};
  addressLatLonData: any = null;
  selectedUnitNumber: any = '00000';
  riskscapePositionLookup: any = {};
  riskscapePropertyDetails: any = {};

  cityTown: string;
  suburbName: string;
  unitNumber: string;
  propertyType: string;
  shortAddress: string;
  propertyKey: string = '';
  erfOrPortionNumber: string;
  cadasterPictureBase64: string;
  emailPattern: string = GlobalConstants.emailPattern;

  cadastre: UploadFileDto;
  validatedForm: FormGroup;
  subscription: Subscription;
  registrationSub: Subscription;

  registrationTypes: SacPlanRegistrationDto[] = [];
  landDevelopmentType: LandDevelopmentTypeDto[] = [];
  municipalCouncilLookup: MunicipalCouncilDto[] = [];
  municipalCouncilChecked: MunicipalCouncilDto[] = [];
  developmentSpeciality: DevelopmentSpecialityDto[] = [];
  developmentTypeChecked: DevelopmentSpecialityDto[] = [];
  landDevelopmentTypeChecked: LandDevelopmentTypeDto[] = [];

  physicalAddress: AddressDto = {
    unitNumber: '',
    complexName: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    postalCode: '',
    addressFull: '',
    propertyDescription: '',
  };

  userForm: UserDto = {
    confirmEmail: '',
    confirmMobile: '',
    email: '',
    idNumber: '',
    isSouthAfricanResident: null,
    landLine: '',
    mobile: '',
    name: '',
    passportNumber: '',
    surname: '',
    userName: '',
    password: '',
    passwordConfirm: '',
    urbanPlanner: {
      graduationInstitution: '',
      graduationYear: 0,
      registrationType: null,
      yearsOfExperience: 0,
      sacPlanRegNumber: '',
      companyDetail: {
        name: '',
        companyRegistrationNumber: '',
        companyVatNumber: '',
        propertyDetailsAddress: '',
        propertyBoundary: '',
        developmentSpeciality: null,
        developmentSpecialityOther: '',
        experienceInMarketResearch: false,
        landDevelopmentType: null,
        landDevelopmentTypeOther: '',
        municipalCouncilOther: '',
        municipalCouncil: null,
        addressDetail: this.physicalAddress,
        cadastre: null,
        propertyDetailsDescription: '',
        contactDetail: {
          confirmEmailAddress: '',
          emailAddress: '',
          isTheContactDetailsToAppearOnInvoice: false,
          surname: '',
          name: '',
          mobile: '',
          landLine: '',
          position: ''
        }
      }
    }
  };

  contactInvoiceDetails: ContactDetailDto = {
    isTheContactDetailsToAppearOnInvoice: null,
    name: '',
    surname: '',
    landLine: '',
    mobile: '',
    emailAddress: '',
    confirmEmailAddress: '',
  };

  organizationInvoiceDetails: OrganizationInvoiceDetailsDto = {
    organizationName: '',
    companyRegistrationNumber: '',
    vatNumber: '',
  };

  individualInvoiceDetails: IndividualInvoiceDetailsVm = {
    name: '',
    surname: '',
  };

  propertyDetailForm?: PropertyDetailDto = {
    invoiceIssuedTo: null,
    isTheOrganizationRegisteredForVat: null,
    isTheContactDetailsToAppearOnInvoice: null,
    isTheAddressToAppearOnInvoice: null,
    propertyAddress: '',
    propertyBoundaryConcern: '',
    wishToSell: null,
    mainPropertyUse: null,
    approximateBuildingAge: null,
    reasonForNotInterested: null,
    hasCopiesOfApprovedBuildingPlans: null,
    jointProperties: null,
    hasAskingPrice: false,
    allBuildingsDoneWithApprovedBuildingPlans: null,
    sellMoreThanOneProperty: false,
    propertyDescription: '',
    confirmCorrectAddressDetails: false,
    mainPropertyUseOther: '',
    approximateBuildingAgeOther: 0,
    hasValueAlteringInfluences: false,
    valueAlteringInfluencesDescription: '',
    tenantedBuildingsAmount: 0,
    hasImprovementPotential: false,
    futurePotential: '',
    latitude: '',
    longitude: '',
    correctAddress: false,
    askingPrice: 0,
    physicalAddress: this.physicalAddress,
    contactInvoiceDetails: this.contactInvoiceDetails,
    organizationInvoiceDetails: this.organizationInvoiceDetails,
    individualInvoiceDetails: this.individualInvoiceDetails,
    riskscapeInfo: {
      neighbourhoodId: '',
      sectionalTitleUnitNumber: '',
      subplaceId: '',
      surveyorGeneralKey: ''
    },
    riskscapeAddressGeocode: {
      data: []
    },
    riskscapeAddress: {
      data: null
    },
    riskscapePositionLookup: {
      data: null
    },
    riskscapePropertyDetails: {
      data: null
    },
  };

  constructor(
    public dialog: MatDialog,
    private _fb: FormBuilder,
    private _mapsService: MapsService,
    private _lookupService: LookupService,
    private _wizardDataService: WizardDataService,
    private _registrationService: RegistrationService,
    private _notificationService: NotificationService,
    private _sharedService: PropertyMatrixSharedService,
    private _sellMyPropertyService: SellMyPropertyService,
  ) {
    this.validatedForm = this._fb.group({
      urbanPlanner: this._fb.group({
        graduationYear: ['', [Validators.required, this.yearValidator.bind(this)]]
      })
    });
  }

  ngOnInit(): void {
    this.loadMunicipalityCouncilLookupData();
    this.loadDevelopmentSpecialityLookupData();
    this.loadLandDevelopmentLookupData();
    this.loadRequiredCadastre();
    this.loadRegistrationTypes();
    this.receiveData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  handleSelectedPropertyChange(property: any): void {
    if (property !== null) {
      this.isPropertySelected = true;
      this.getAddressFromLatLon(property.position.lat, property.position.lon);
    } else {
      this.isPropertySelected = false;
      this.confirmPropertyDetail = 1;
      this._notificationService.showWarningMessage(
        'Warning',
        'Address not found or Riskscape is unavailable. Please re-enter the address or try again later.');
      GlobalMethods.scrollToBottom();
    }
  }

  handleSelectedUnitChange(unit: any): void {
    this.selectedUnitNumber = unit;
  }

  handleScriptLoadStatus(statusMessage: string): void {
    if (statusMessage) {
      this._notificationService.showWarningMessage(
        'Oops! Something went wrong.',
        statusMessage
      );
    }
  }

  yearValidator(control: FormControl): { [key: string]: any } | null {
    const currentYear = new Date().getFullYear();
    const inputYear = Number(control.value);
    return inputYear > currentYear ? { 'yearInvalid': true } : null;
  }

  receiveData() {
    this.registrationSub = this._registrationService.userData.subscribe(userData => {
      this.userForm.confirmEmail = userData.confirmEmail;
      this.userForm.confirmMobile = userData.confirmMobile;
      this.userForm.email = userData.email;
      this.userForm.idNumber = userData.idNumber;
      this.userForm.isSouthAfricanResident = userData.isSouthAfricanResident;
      this.userForm.urbanPlanner.plannerEmail = userData.urbanPlanner.plannerEmail;
      this.userForm.urbanPlanner.plannerName = userData.urbanPlanner.plannerName;
      this.userForm.urbanPlanner.plannerSurname = userData.urbanPlanner.plannerSurname;
      this.userForm.urbanPlanner.plannerMobile = userData.urbanPlanner.plannerMobile;
      this.userForm.landLine = userData.landLine;
      this.userForm.mobile = userData.mobile;
      this.userForm.name = userData.name;
      this.userForm.passportNumber = userData.passportNumber;
      this.userForm.surname = userData.surname;
      this.userForm.userName = userData.userName;
      this.userForm.passwordConfirm = userData.passwordConfirm;
      this.userForm.password = userData.password;
      this.userForm.urbanPlanner.sacPlanRegNumber = userData.urbanPlanner.sacPlanRegNumber;
      this.userForm.urbanPlanner.registrationType = userData.urbanPlanner.registrationType;
    });
    this._registrationService.setUserEnrollmentData(this.userForm);
  }

  loadRegistrationTypes() {
    this._lookupService.apiV1LookupGetUrbanPlannerRegistrationTypesGet().subscribe({
      next: (result: SacPlanRegistrationDto[]) => {
        this.registrationTypes = result;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not load registration types.');
      }
    })
  }

  loadRequiredCadastre() {
    this._sellMyPropertyService.apiV1SellMyPropertyLoadRequiredCadastreGet().subscribe({
      next: (res: UploadFileDto) => {
        this.cadastre = res;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not load required cadastre.');
      }
    });
  }

  getRegistrationType(registrationId: any) {
    this.userForm.urbanPlanner.registrationType = this.registrationTypes.find(x => x.id == registrationId)
  }

  municipalOtherCheck(option: MunicipalCouncilDto): boolean {
    return option.intValue == 6 && option.isChecked;
  }

  selectedMunicipalCouncil(municipalCouncil: MunicipalCouncilDto) {
    const selectedMunicipalCouncil = this.municipalCouncilLookup.find(x => x.id == municipalCouncil.id);
    if (selectedMunicipalCouncil) {
      if (selectedMunicipalCouncil.isChecked) {
        this.municipalCouncilChecked.push(selectedMunicipalCouncil);
      } else {
        const index = this.municipalCouncilChecked.findIndex(x => x.id == municipalCouncil.id);
        if (index !== -1) {
          this.municipalCouncilChecked.splice(index, 1);
        }
      }
    }
    this.userForm.urbanPlanner.companyDetail.municipalCouncil = this.municipalCouncilChecked;
    this.municipalOtherCheck(municipalCouncil);
  }

  selectedDevelopmentType(developmentType: DevelopmentSpecialityDto) {
    const selectedDevelopmentType = this.developmentSpeciality.find(x => x.id == developmentType.id);
    if (selectedDevelopmentType) {
      if (selectedDevelopmentType.isChecked) {
        this.developmentTypeChecked.push(selectedDevelopmentType);
      } else {
        const index = this.developmentTypeChecked.findIndex(x => x.id == developmentType.id);
        if (index !== -1) {
          this.developmentTypeChecked.splice(index, 1);
        }
      }
    }
    this.userForm.urbanPlanner.companyDetail.developmentSpeciality = this.developmentTypeChecked;
    this.developmentTypeOtherCheck(developmentType);
  }

  developmentTypeOtherCheck(option: DevelopmentSpecialityDto): boolean {
    return option.intValue == 8 && option.isChecked;
  }

  selectedLandDevelopmentType(landDevelopmentType: LandDevelopmentTypeDto) {
    const selectedLandDevelopmentType = this.landDevelopmentType.find(x => x.id == landDevelopmentType.id);
    if (selectedLandDevelopmentType) {
      if (selectedLandDevelopmentType.isChecked) {
        this.landDevelopmentTypeChecked.push(selectedLandDevelopmentType);
      } else {
        const index = this.landDevelopmentTypeChecked.findIndex(x => x.id == landDevelopmentType.id);
        if (index !== -1) {
          this.landDevelopmentTypeChecked.splice(index, 1);
        }
      }
    }
    this.userForm.urbanPlanner.companyDetail.landDevelopmentType = this.landDevelopmentTypeChecked;
    this.planningOtherCheck(landDevelopmentType);
  }

  planningOtherCheck(option: LandDevelopmentTypeDto): boolean {
    return option.intValue == 5 && option.isChecked;
  }

  loadMunicipalityCouncilLookupData() {
    this._lookupService.apiV1LookupGetMunicipalLookupValuesGet().subscribe({
      next: (result: MunicipalCouncilDto[]) => {
        this.municipalCouncilLookup = result;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not find municipality councils.');
      }
    })
  }

  loadDevelopmentSpecialityLookupData() {
    this._lookupService.apiV1LookupGetDevelopmentSpecialityLookupValuesGet().subscribe({
      next: (result: DevelopmentSpecialityDto[]) => {
        this.developmentSpeciality = result;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not fetch find development specialties.');
      }
    })
  }

  loadLandDevelopmentLookupData() {
    this._lookupService.apiV1LookupGetLandDevelopmentLookupValuesGet().subscribe({
      next: (result: LandDevelopmentTypeDto[]) => {
        this.landDevelopmentType = result;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not find land development data.');
      }
    })
  }

  setExperienceInMarketResearch(value: boolean) {
    this.experienceInMarketResearch = value;
    this.userForm.urbanPlanner.companyDetail.experienceInMarketResearch = this.experienceInMarketResearch;
  }

  selectButton(buttonNumber: number) {
    this.selectedButton = buttonNumber;
    this.confirmPropertyDetail = buttonNumber;
  }

  selectBoundaryButton(buttonNumber: number) {
    this.selectedBoundaryButton = buttonNumber;
    this.confirmPropertyBoundary = buttonNumber;
  }

  setWizardData() {
    this._wizardDataService.setUrbanPlannerInfoEnrollmentData(this.userForm);
  }

  getAddressFromLatLon(lat: string, lon: string) {
    this._mapsService.apiV1MapsGetPropertyDetailsFromLatLonGet$Response({
      lat: lat,
      lon: lon
    }).subscribe({
      next: (result: any) => {
        this.propertyDetailForm.riskscapeAddressGeocode.data[0] = null;
        this.propertyDetailForm.riskscapePositionLookup.data = result.body;
        this.addressLatLonData = this.propertyDetailForm.riskscapePositionLookup.data;
        this.propertyType = this.addressLatLonData.type;
        if (this.addressLatLonData.attributes.property_key) {
          this.getAddressDetails(this.addressLatLonData.attributes.address_id);
          this.getAddressImage(this.addressLatLonData.attributes.property_key);
          this.getPropertyDetails(this.addressLatLonData.attributes.property_key, this.selectedUnitNumber);
          this.propertyDetailForm.riskscapeInfo.surveyorGeneralKey = this.addressLatLonData.attributes.property_key;
          this.propertyDetailForm.riskscapeInfo.sectionalTitleUnitNumber = this.selectedUnitNumber;
        }
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not find address.');
      }
    });
  }

  getAddressDetails(addressId: string) {
    this._mapsService.apiV1MapsGetAddressDetailsGet$Response({
      addressId: addressId
    }).subscribe({
      next: (result: any) => {
        this.propertyDetailForm.riskscapeAddress.data = result.body;
        this.propertyDetailForm.latitude = this.propertyDetailForm.riskscapeAddress.data.position.lat.toString();
        this.propertyDetailForm.longitude = this.propertyDetailForm.riskscapeAddress.data.position.lon.toString();
        this.physicalAddress = {
          unitNumber: this.selectedUnitNumber,
          complexName: this.propertyDetailForm.riskscapeAddress.data.complex ?? '',
          streetNumber: this.propertyDetailForm.riskscapeAddress.data.street_number,
          streetName: this.propertyDetailForm.riskscapeAddress.data.street,
          suburb: this.propertyDetailForm.riskscapeAddress.data.subplace,
          city: this.propertyDetailForm.riskscapeAddress.data.city_town,
          postalCode: this.propertyDetailForm.riskscapeAddress.data.postal_code,
        }
        this.unitNumber = this.physicalAddress.unitNumber == undefined ? 'N/A' : this.physicalAddress.unitNumber;
        this.erfOrPortionNumber = result.body.erf_or_portion_nr == undefined ? 'N/A' : result.body.erf_or_portion_nr;
        this.shortAddress = this.physicalAddress.streetNumber + ' ' + this.physicalAddress.streetName;
        this.suburbName = this.physicalAddress.suburb == undefined ? 'N/A' : this.physicalAddress.suburb;
        this.cityTown = this.physicalAddress.city == undefined ? 'N/A' : this.physicalAddress.city;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not load address details.');
      }
    });
  }

  async getAddressImage(propertyKey: string) {
    this.loadingCadaster = true;
    let result = await this._sharedService.getAddressImage(propertyKey);
    if (result?.ok) {
      const resultJson = JSON.parse(result.body);
      this.cadasterPictureBase64 = resultJson.encoded;
      const base64Response = await fetch(`data:image/jpeg;base64,${this.cadasterPictureBase64}`);
      const blob = await base64Response.blob();
      let objectURL = URL.createObjectURL(blob);
      this.cadasterPicture = await this._sharedService.getImageFromDataUri(objectURL);
      this.cadastre.file = blob;
    } else {
      this._notificationService.showErrorMessage('Error', 'Could not find address image.');
    }
    this.loadingCadaster = false;
  }

  async getPropertyDetails(propertyKey: string, unitNumber: string) {
    this._mapsService.apiV1MapsGetPropertyDetailsGet$Response({
      propertyKey: propertyKey,
      unitNumber: unitNumber
    }).subscribe({
      next: (result: any) => {
        this.propertyDetailForm.riskscapePropertyDetails.data = result.body;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not load property details.');
      }
    });
  }

  submitForm() {
    if (this.userForm.urbanPlanner.companyDetail.experienceInMarketResearch &&
      (this.userForm.urbanPlanner.companyDetail.developmentSpeciality == null ||
        this.userForm.urbanPlanner.companyDetail.developmentSpeciality.length == 0)) {
      GlobalMethods.tinyErrorAlert('Error', 'Please make sure a Development Speciality is selected').then(result => {
      });
      return;
    }
    if (this.userForm.urbanPlanner.companyDetail.landDevelopmentType == null || this.userForm.urbanPlanner.companyDetail.landDevelopmentType.length == 0) {
      GlobalMethods.tinyErrorAlert('Error', 'Please make sure a land Development type is selected').then(result => {
      });
      return;
    }
    if (this.userForm.urbanPlanner.companyDetail.municipalCouncil == null || this.userForm.urbanPlanner.companyDetail.municipalCouncil.length == 0) {
      GlobalMethods.tinyErrorAlert('Error', 'Please make sure a Development Speciality is selected').then(result => {
      });
      return;
    }
    this.getRegistrationType(this.userForm.urbanPlanner.registrationType);
    this.userForm.urbanPlanner.graduationYear = this.validatedForm.get('urbanPlanner').get('graduationYear').value;
    this.setWizardData();
    this.pageNumberEvent.emit(2);
    this.cadastreEvent.emit(this.cadastre);

    try {
      if (this.isPropertySelected) {
        this.propertyDetailForm.riskscapeInfo.addressId = this.propertyDetailForm.riskscapePropertyDetails.data.address_id;
        this.propertyDetailForm.riskscapePositionLookup.data.attributes.address_id = this.propertyDetailForm.riskscapePropertyDetails.data.address_id;
        this.propertyDetailForm.riskscapePositionLookup.data.attributes.neighbourhood_id = this.propertyDetailForm.riskscapePropertyDetails.data.neighbourhood_id;
        this.propertyDetailForm.riskscapePositionLookup.data.attributes.subplace_id = this.propertyDetailForm.riskscapePropertyDetails.data.subplace_id;
        this.propertyDetailForm.riskscapePositionLookup.data.attributes.scheme_id = this.propertyDetailForm.riskscapePropertyDetails.data.scheme_id;
        if (this.propertyDetailForm.riskscapePositionLookup.data.attributes.property_key == null) {
          this.propertyDetailForm.riskscapePositionLookup.data.attributes.property_key = this.propertyKey;
        }
      } else {
        this.propertyDetailForm.riskscapePositionLookup.data = new Object();
        this.propertyDetailForm.riskscapePropertyDetails.data = new Object();
        this.propertyDetailForm.riskscapePropertyDetails.data.position = new Object();
      }
      this.propertyDetailForm.correctAddress = this.confirmCorrectAddress == 1;
      this.propertyDetailForm.imageBase64 = this.cadasterPictureBase64;
      this.propertyDetailForm.physicalAddress = this.physicalAddress;
      this.propertyDetailForm.hasAskingPrice = this.propertyDetailForm.hasAskingPrice ?? false;
      this.propertyDetailForm.askingPrice = this.propertyDetailForm.hasAskingPrice ? this.propertyDetailForm.askingPrice : 0;
      GlobalMethods.scrollToTop();
    } catch (_error: any) {
      this._notificationService.showWarningMessage('Warning', 'Riskscape Property Information could not be found. Please contact Property Matrix for support.');
    }
  }
}
