import { RegistrationService } from 'src/app/services/property-matrixV2/custom-services/registration.service';
import { SacPlanRegistrationDto, UrbanPlannerDto, UserDto } from 'src/app/services/property-matrixV2/models';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, EventEmitter, Output, Inject, OnInit } from '@angular/core';
import { LookupService } from 'src/app/services/property-matrixV2/services';
import { GlobalConstants } from 'src/app/common/global-constants';
import { GlobalMethods } from 'src/app/common/global-methods';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, catchError, of } from 'rxjs';

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class PlannerComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() UrbanPlannerDetail = new EventEmitter<UrbanPlannerDto>();

  loading: boolean = false;
  isProfessional: boolean = null;

  userType: string = '';
  emailPattern: string = GlobalConstants.emailPattern;
  passwordPattern: string = GlobalConstants.passwordPattern;

  registrationSub: Subscription;
  sacPlanRegNumber: FormControl;
  registrationTypes: SacPlanRegistrationDto[] = [];
  showSacPlanError: boolean = false;

  form: UserDto = {
    urbanPlanner: {
      sacPlanRegNumber: '',
      registrationType: null,
    }
  }

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public lookupService: LookupService,
    private notificationService: NotificationService,
    private registrationService: RegistrationService,
    private sharedService: PropertyMatrixSharedService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.userType = params['userType'];
    });

    const user = this.sharedService.getUserModel();

    this.loadRegistrationTypes();

    if (user) {
      this.router.navigateByUrl('/collaboration-agreement');
    }

    this.sacPlanRegNumber = new FormControl('', [
      Validators.required,
      Validators.pattern(/^[A-Z]\d{4}\/(19|20)\d{2}$/)
    ]);

    this.sacPlanRegNumber.statusChanges.subscribe(status => {
      this.showSacPlanError = status === 'INVALID' && (this.sacPlanRegNumber.dirty || this.sacPlanRegNumber.touched);
    });
  }

  loadRegistrationTypes() {
    this.lookupService.apiV1LookupGetUrbanPlannerRegistrationTypesGet()
      .pipe(
        catchError((_error) => {
          return of(
            this.notificationService.showErrorMessage(
              'Error',
              'Error loading registration types.'
            )
          );
        })
      )
      .subscribe((result) => {
        if (result != null) {
          this.registrationTypes = result as SacPlanRegistrationDto[];
          this.registrationTypes.sort((a, b) => a.intValue - b.intValue);
        }
      });
  }

  selectRegistrationType(event: any) {
    this.form.urbanPlanner.registrationType = this.registrationTypes.find(x => x.id == event);
    if (this.form.urbanPlanner.registrationType.value == 'Professional Planner') {
      this.isProfessional = true;
    } else {
      this.isProfessional = false;
    }
  }

  proceed() {
    this.form.urbanPlanner.sacPlanRegNumber = this.sacPlanRegNumber.value;

    if (this.form.urbanPlanner.registrationType == null || this.sacPlanRegNumber.value == '') {
      GlobalMethods.tinyErrorAlert('Error', 'Please ensure all required fields are completed.');
      return;
    }

    if (this.isProfessional == false) {
      GlobalMethods.tinyErrorAlert('Error', 'Sacplan registration must be professional.');
      return;
    }

    const dialogRef = this.dialog.open(CollaborationDialog, {
      data: {
        form: this.form,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.registrationService.setUserEnrollmentData(this.form);
      this.router.navigate(
        ['/register'],
        {
          queryParams: {
            userType: this.userType
          }
        });
    });
  }
}

@Component({
  selector: 'collaboration-dialog',
  templateUrl: './collaboration-agreement.html',
  styleUrls: ['./planner.component.scss', '../../../../css/2-modules/_forms.scss'],
})
export class CollaborationDialog {

  constructor(
    public dialogRef: MatDialogRef<CollaborationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: CollaborationDialog,
  ) { }

  agree() {
    this.dialogRef.close();
  }
}
